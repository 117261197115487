*{
    font-family: 'Source Sans Pro', sans-serif;
}

.container{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: black;
}

.ErrorContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
