*{
  margin: 0;
  padding: 0;
}

/* .Dropdown-root{
    position: absolute !important;
    z-index: 1000;
    top: 10px;
    right: 10px;
} */

/* .css-13cymwt-control{
  background-color: #414147 !important;
  color: white;
} */
.hideMarkers{
  display: none;
}

.react-select__placeholder{
  color: white !important;
}

.react-select__single-value{
  color: white !important;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.menu_item{
  padding: 0 !important;
}

#demo-multiple-checkbox{
    padding: 10px; 
}

.Toastify__toast-container--top-right{
  top: 3em !important;
}
