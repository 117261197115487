*{
    font-family: 'Source Sans Pro', sans-serif;
}

.container{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: black;
}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    height: 100px;
    width: 100vw;
    /* border-bottom: 2px solid white; */
}

.menu_icon{
    width: 50px;
    height: 30px;
    cursor: pointer;
}

.left_navbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    margin-left: 2vw;
}

.center_navbar > h2{
    color: white;
    font-size: 5vmin;
}

.right_navbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content{
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.left_content{
    flex-basis: 50%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}

.right_content{
    flex-basis: 50%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    /* width: 100%;
    height: 90%; */
    margin-top: 8%;
}

.top_div{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 2.5vmin;
    /* margin-top: 15%; */
}
.bottom_div{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.cauvery_div{
    flex-basis: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.top_div >h3{
    color: white;
    margin-bottom: 2%;
}
.map_heading{
    color: white;
    margin-bottom: 2%;
    margin-left: 2%;
}

.bottom_div >h3{
    color: white;
    margin-bottom: 2%;
}

.top_grid{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex:1 1 50%;
    
    gap: 20px;
    height: 100%;
}
.bottom_grid{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex:1 1 50%;
    /* padding: 4%; */
    gap: 20px;
}
.cauvery_div >h3{
    color: white;
}

.campus_grid_left{
    flex-basis: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.campus_grid_right{
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    width: 100%;
    height: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.small_grid_items{
    flex: 1 1 50%;
    background-color: #414148;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.large_grid_items{
    flex: 1 1 50%;
    background-color: #414148;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.first_row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 20px;
    flex: 1 1 50%;
    width: 100%;
}

.second_row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 20px;
    flex: 1 1 50%;
    width: 100%;
}

.large_grid_items_inner_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.large_grid_contents > p{
    font-size: 4vmin;
    color: #DDC9C8;
}

.large_grid_values > p{
    font-size: 6vmin;
    font-weight: 600;
    color: white;
}

.large_grid_values > p > span{
    font-size: 2vmin;
    margin-bottom: 4%;
    color: #DDC9C8;
}
.small_grid_items_inner_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.small_grid_contents > p{
    font-size: 2vmin;
    color: #DDC9C8;
    font-weight: 400;
}

.small_grid_values > p{
    font-size: 3vmin;
    font-weight: 600;
    color: white;
}

.small_grid_values > p > span{
    font-size: 1.2vmin;
    margin-bottom: 2%;
    color: #DDC9C8;
    font-weight: 400;
}

.carousel_tag{
    width: 600px;
    height: 50px;
    background-color: black;
}

.carousel_content{
    color: white;
    font-size: 3.5vmin;
}

.center_content{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.tooltip{
    background-color: black;
    color: white;
}

#leaflet-tooltip-99{
    background-color: black !important;
}

.checkbox_div{
    display: flex;
    flex-direction: row-reverse;
    color: white;
    margin-left: 10%;
    margin-right: 5%;
    align-items: center;
    justify-content: space-between;
}
.checkbox_div > label{
    color: white;
    margin-left: 20px;
}

input{
    width: 15px;
    height: 15px;

}
.first_checkbox{
    background-color: #8884d8 !important;
}

.center_navbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.center_navbar > img{
    width: 150px;
    height: 50px;
}

.loading_div{
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10;
}

.chart_loading_div{
    position: relative;
    left: 50%;
    top: 50%;
    z-index: 10;
}

.opacity{
    opacity: 0.1;
}

.sidebar{
    margin-top: 5vmin;
}

.sidebar_text{
    color: white;
    margin-left: 10%;
    cursor: pointer;
    font-size: 20px;
}
.menu_active{
    background-color: white;
    color: black;
    padding: 0px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    border-radius: 10px !important;
}

.navbar_logo{
    margin-left: 0%;
    width: 250px !important;
}

.sidenav_top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.close_icon{
    width: 50px;
    height: 30px;
    cursor: pointer;
    margin-top: 5%;
    position: absolute;
    right: 0;
}

.radiobtn_div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

input[type="radio"]#red {
    accent-color: red !important;
    border: 2px solid red;
}

.load_btn{
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    background-color: green;
    color: white;
}
.heatmap_btn{
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    background-color: yellowgreen;
    color: white;
    position: absolute;
    z-index: 500 !important;
    right: 10px;
    top: 10px;
}

.Map_nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.load_btn_container{
    display: flex;
    flex: 1 1 auto;
    flex-direction: row-reverse;
    margin-left: 3%;
    gap: 3%;
    z-index: 1001;
}

.options_div{
    position: absolute;
    top: 35px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200%;
    background-color: #414148;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border-top: 2px solid white;
    border-radius: 0px 0px 5px 5px;
}



.map{
    border: 1px solid white;
}

.option_item{
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    background-color: #414148;
    color: white;
    padding: 10px 0px;
    border-radius: 0px 0px 5px 5px;
}

.option_btn{
    padding: 5px 25px;
    background-color: #414148;
    border: none;
    color: white;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
}

.parameter{
    font-family: 'Ubuntu';
}

.nullData{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.legend_div{
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 15%;
    height: 20%;
    background-color: white;
    z-index: 1000;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.legend_content{
    width: 60%;
    height: 80%;
    display:flex;
    flex-direction: column;
}

.legend_item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
}

.inner_map_dropdown{
    position: absolute !important;
    z-index: 1000;
    top: 10px;
    right: 10px;
}

.dropdown_icon{
    position: relative;
    left: 20%;
}

.option_btn{
    display: flex;
    align-items: center;
    justify-content: center;
}















